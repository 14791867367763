legend {
    font-size: 1em;
}

.filters-section {
  width: var(--filters-width);
  padding-top: 6px;
  margin-left: 48px;
  margin-right: 7px;
  background-color: var(--filter-box-color);
  color: var(--filter-box-text-color);
  font-size: 10px;
}

.filter-title {
  width: var(--filters-width);
  padding-left: 18px;
  margin-top: 15px;
  text-align: left;
  font-size: 1.8em;
  height: 24px;
  box-sizing: border-box;
  line-height: normal;
}

.filter-group {
  width: 100%;
}

.filter-group-title {
  width: 100%;
  padding-left: 18px;
  text-align: left;
  font-size: 1.6em;
  height: 32px;
  margin-top: 16px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--borders-color);
  box-sizing: border-box;
  line-height: normal;
}

.filter-item {
  text-align: left;
  font-size: 1.6em;
  height: 24px;
  margin-top: 8px;
  margin-left: 18px;
  box-sizing: border-box;
  line-height: normal;

  .form-check-input {
    width: 24px;
    height: 24px;
    margin-top: 0px;
    border-radius: 0;
    border: none;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='24px' viewBox='66 316 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 66.5 316.5 L 89.5 316.5 L 89.5 339.5 L 66.5 339.5 L 66.5 316.5 Z ' fill-rule='nonzero' fill='%23ffffff' stroke='none' /%3E%3Cpath d='M 66.5 316.5 L 89.5 316.5 L 89.5 339.5 L 66.5 339.5 L 66.5 316.5 Z ' stroke-width='1' stroke='%23797979' fill='none' /%3E%3C/svg%3E");
  }

  .form-check-input:checked[type=checkbox] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' width='24px' height='24px' viewBox='66 316 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 66.5 316.5 L 89.5 316.5 L 89.5 339.5 L 66.5 339.5 L 66.5 316.5 Z ' fill-rule='nonzero' fill='%23ffffff' stroke='none' /%3E%3Cpath d='M 66.5 316.5 L 89.5 316.5 L 89.5 339.5 L 66.5 339.5 L 66.5 316.5 Z ' stroke-width='1' stroke='%23797979' fill='none' /%3E%3Cpath d='M 71.14285714285714 328 L 76.28571428571429 333.1428571428571 L 84.85714285714286 321.14285714285717 ' stroke-width='5.142857142857142' stroke='%23797979' fill='none' /%3E%3C/svg%3E");
  }


  .form-check-label {
    height: 24px;
    margin-left: 4px;
    padding-top: 5px;
    text-overflow: ellipsis ellipsis;
  }
}