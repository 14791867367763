.checkout-path {
    height: 19px;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    text-align: left;
    line-height: normal;
    margin-bottom: 30px;
}

.inline{
    display:inline-block;
}

#termsCheck {
    margin-right: 5px;
}

#redirectDynam {
    height: 96px;
    padding: 2px 2px 2px 2px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    text-align: center;
    line-height: normal;
    margin-bottom: 23px;
}