.footer {
  width: var(--components-width);
  max-height: 126px;
  padding: 2px 2px 2px 2px;
  background-color: var(--footer-color);
  color: var(--footer-text-color);
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  font-family: "Inter Light", Inter, sans-serif;
  font-size: 10px;
  overflow: hidden;

  div {
    margin: 0ñ
  }
}
