.catalog-item-card {
  width: 240px;
  height: 370px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: var(--card-box-color);
  color: var(--card-box-text-color);
  box-sizing: border-box;
  font-size: 10px;
  overflow: hidden;
  cursor: pointer;
}

.catalog-item-card:hover {
  box-shadow: 5px 5px 5px var(--borders-color);
}

.catalog-item-tile {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.catalog-item-card-image {
    width: 240px;
    height: 160px;
    box-sizing: border-box;
}

.catalog-item-tile-image{
    width: 50%;
    height: 100%;
}

.catalog-item-title {
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  text-align: left;
  height: 57px;
  line-height: normal;
  font-size: 1.6em;
  margin-top: 16px;
  text-overflow: ellipse ellipse;
  overflow: hidden;
}

.catalog-item-descr {
  box-sizing: border-box;
  text-align: left;
  line-height: normal;
  font-size: 1.4em;
  margin-top: 13px;
  width: 100%;
  height: 83px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.catalog-item-price-box {
  width: 100%;
  margin-top: 8px;
  height: 32px;
  box-sizing: border-box;
  line-height: normal;
  display: flex;
}

.catalog-item-price {
  text-align: right;
  color: var(--price-text-color);
  padding: 6px;
  padding-right: 3px;
  padding-left: 0;
  font-size: 1.6em;
}

.catalog-item-langs {
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 10px;
}

.catalog-item-lang {
  height: 32px;
  background-color: transparent;
  box-sizing: border-box;
  padding: 8px;
  padding-left: 0px;
  color: var(--primary-text-color);
  text-align: left;
  line-height: normal;
  display: flex;
}

.catalog-lang-image {
  width: 14px;
  height: 16px;
  box-sizing: border-box;
}

.catalog-lang-text {
  padding-right: 2px;
  margin-left: 4px;
  width: 100%;
  height: 16px;
  font-size: 1.4em;
}
