.seat-sel-comp {
  width: 120px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid var(--borders-color);
  display: flex;
}

.dec-button {
  width: 30px;
  height: 34px;
  font-size: 1.6em;
  border: none;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-right: 1px solid var(--borders-color);
  background-color: transparent;
  margin-right: 4px;
}

.inc-button {
  width: 30px;
  height: 34px;
  font-size: 1.6em;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border: none;
  border-left: 1px solid var(--borders-color);
  background-color: transparent;
}

.inc-button:hover, .dec-button:hover {
  background-color: #eeeeff;
}

.counter-value {
  width: 60px;
  height: 34px;
  padding-top: 8px;
  font-size: 1.8em;
  text-align: center;
}