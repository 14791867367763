.item-langs {
  width: 167px;
  height: 26px;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  bottom: 41px;
  font-size: 10px;
}

.item-lang {
  height: 26px;
  border-radius: 4px;
  border: 1px solid var(--borders-color);
  background-color: var(--primary-color);
  box-sizing: border-box;
  font-family: "Inter Light", "Inter", sans-serif;
  font-weight: 300;
  color: var(--primary-text-color);
  text-align: left;
  line-height: normal;
  display: flex;

  span {
    font-size: 1.2em;
    padding: 4px;
  }
}

.lang-image {
  width: 14px;
  height: 16px;
  box-sizing: border-box;
  margin: 4px;
}

.cart-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 31px;
}

.item-image-box {
  width: 169px;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
}

.item-image {
  width: 169px;
  height: 112px;
  border-radius: 8px;
  border: 1px solid var(--borders-color);
}

.item-texts {
  width: 100%;
  margin-left: 16px;
  text-align: left;
}

.item-title {
  height: 24px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: normal;
  font-size: 1.8em;
}

.item-desc {
  box-sizing: border-box;
  text-align: left;
  line-height: normal;
  font-size: 1.4em;
  overflow: hidden;
  height: 74px;
}

.seat-selector {
  height: 50px;
}

.seat-sel-text {
  font-size: 1.4em;
  font-weight: 500;
  color: var(--primary-text-color);
}

.cart-item-price {
  box-sizing: border-box;
  font-family: "Inter Medium", "Inter", sans-serif;
  font-weight: 500;
  color: var(--price-text-color);
  text-align: right;
  line-height: normal;
  width: 250px;
}

.item-price-value {
  height: 24px;
  box-sizing: border-box;
  font-weight: 500;
  text-align: right;
  line-height: normal;
  font-size: 1.8em;
}

.remove-link {
  height: 19px;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: var(--remove-text-color);
  text-align: right;
  line-height: normal;
  text-decoration: none;
}
