/* Error handling */
.error {
  margin: 0px auto;
  width: var(--components-width);
  text-align: center;
  font-family: "Inter Light", Inter, sans-serif;

  .errorTitle {
    color: red;
  }

  .errorBox {
    text-align: left;

    .errorMessage {
      font-size: 1.2em;
    }

    .errorFunction {
      font-size: 1.2em;
    }

    .errorLocation {
      color: black;
      background-color: yellow;
      border: 1px solid;
    }
  }
}