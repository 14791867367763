.nav-bar {
  position: fixed;
  top: 0;
  width: var(--components-width);
  height: 56px;
  padding: 2px 2px 2px 2px;
  background-color: var(--navigation-color);
  color: var(--navigation-text-color);
  font-size: 10px;
  box-sizing: border-box;
  box-shadow: 0px 1px 7px 0px var(--navigation-border-color);
  display: flex;
}

.branding-icon {
  width: 144px;
  height: 37px;
  margin-left: 14px;
  margin-top: 10px;
  box-sizing: border-box;
  color: var(--navigation-text-color);
  line-height: normal;
}

.branding-label {
  width: 200px;
  margin-left: 5px;
  margin-top: 16px;
  height: 24px;
  box-sizing: border-box;
  color: var(--navigation-text-color);
  font-size: 1.8em;
  text-align: left;
  line-height: normal;
}

.nav-left-options {
  color: var(--navigation-text-color);
}

.nav-options {
  width: 100%;
  font-size: 10px;
  color: var(--navigation-text-color);
  height: 41px;
  margin-top: 8px;
  display: flex;
}

.nav-option {
  color: var(--navigation-text-color);
  text-decoration: none;
  height: 41px;
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  font-size: 1.4em;
  display: inline-block;
}

.nav-left-option {
  border-radius: 4px;
  border-style: none;
  background-color: var(--detail-color);
  color: var(--detail-text-color);
  font-size: 1.4em;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 11px;
}

.nav-right-options {
  position: absolute;
  right: 10px;
  color: var(--navigation-text-color);
  margin-top: 14px;
}

.nav-image {
  height: 21px;
  width: 26px;
  padding-right: 2px;
}

.cart-item-count {
  color: var(--button-color);
  width: 26px;
  height: 21px;
  margin-left: -26px;
  margin-top: -2px;
  text-align: center;
  display: inline-block;
  font-size: 0.8em;
}

.skip-link {
  position: absolute;
  top: -50px;
  left: 0;
  padding: 6px;
}

.skip-link:focus {
  position: static;
  left: 0;
  top: 0;
}

.lang-selector
{
   select {
      border: none;
      font-size: 1.4em;
      position: relative;
      top: -6px;
   }
}

.top6 {
  margin-top: 6px;
}
