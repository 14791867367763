.catalog-title {
  height: 32px;
  font-weight: 200;
  text-align: left;
  line-height: normal;
  margin-top: 46px;
  padding-left: 48px;

  span {
    font-size: 2.4em;
    height: 32px;
    border-bottom: 2px solid var(--borders-color);
  }
}

.catalog {
  width: var(--components-width);
  display: inline-flex;
  flex-direction: row;
  margin-top: 26px;
  font-size: 10px;
  font-family: "Inter Light", "Inter", sans-serif;
  text-align: left;
}

.catalog-section {
  width: 100%;
  display: block;
}

.catalog-title-bar {
  display: inline-flex;
  width: 100%;
  height: 43px;
  column-gap: 10px;
}

.results-count {
  height: 43px;
  width: 100%;
  font-weight: 300;
  text-align: left;
  line-height: normal;
  font-size: 3.2em;

  span {
    font-size: 0.5em;
    padding: 12px;
  }
}

.catalog-sort {
  width: 160px;
  float: right;
  text-align: right;
  margin-right: 20px;

  div label .mt {
    width: 140px;
    height: 40px;
    padding: 2px 16px 2px 2px;
    border-radius: 8px;
    border: 1px solid var(--button-color);
    background-color: transparent;
    box-sizing: border-box;
    color: var(--button-color);
    text-align: center;
    line-height: normal;
    font-size: 1.6em;
    margin-top: 0;
  }
}

.catalog-search-bar {
  width: 100%;
}

.catalog-sel-filters {
  width: 100%;
  height: 32px;
  display: inline-flex;
  flex-direction: row;
  padding-left: 8px;
  column-gap: 3px;
}

.catalog-items-box {
  width: 100%;
  margin-top: 12px;
  display: flex;
  padding-bottom: 20px;
}

.catalog-items {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  gap: 20px;
  column-gap: 8px;
}

.catalog-selected-filter {
  padding: 2px 8px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid var(--borders-color);
  background-color: var(--sel-filter-color);
  color: var(--sel-filter-text-color);
  text-align: center;
  line-height: 28px;
  font-size: 1.4em;

  .remove-filter
  {
    margin-left: 6px;
    cursor: pointer;
  }

  .remove-filter:hover
  {
    font-weight: bold;
  }
}

.catalog-app-icon {
  width: 40px;
  height: 40px;
  padding: 7px 7px 8px 8px;
  border-radius: 4px;
  border: 1px solid var(--button-color);
  background-color: transparent;
  box-sizing: border-box;
}

.catalog-date-icon {
  width: 40px;
  height: 40px;
  padding: 7px 7px 8px 8px;
  background-color: transparent;
  box-sizing: border-box;
}