input {
    margin-bottom: 15px;
    margin-top: 5px;
}

#registerPasswordRulesDynam {
    height: 96px;
    padding: 2px 2px 2px 2px;
    background-color: #f2f2f2;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    text-align: center;
    line-height: normal;
}

.checkout {
    margin: 0px auto;
    margin-top: 136px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    line-height: normal;
    background-color: var(--primary-color);
    color: var(--primary-text-color);
}

.inline {
    display: inline-block;
    margin: 30px;
}