.detail-shadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.3);
}

.detail-popup {
  position: relative;
  top: 100px;
  width: var(--item-detail-popup-width);
  margin: auto auto;
  min-height: 360px;
  max-height: 952px;
  background-color: var(--detail-color);
  color: var(--detail-text-color);
  font-size: var(--base-font-size);
  border: 1px solid var(--borders-color);
  text-align: left;
}

.detail-pop-title {
  width: 100%;
  height: 79px;
  display: inline-flex;
  border-bottom: 1px solid var(--navigation-border-color);
}

.detail-pop-title span {
  width: 100%;
  font-size: 2.4em;
  height: 32px;
  margin-top: 27px;
  padding-left: 32px;
}

.detail-close-button {
  border: none;
  height: 32px;
  background-color: var(--detail-color);
  color: var(--detail-text-color);
  padding: 0;
  font-size: 2.4em;
  float: right;
  text-align: right;
  margin-right: 23px;
  margin-top: 27px;
}

.detail-pop-split {
  display: inline-flex;
  flex-direction: row;
  margin-top: 29px;
  padding-left: 32px;
  padding-right: 27px;
  padding-bottom: 24px;
  column-gap: 10px;
}

.detail-pop-split-data {
  width: 100%;
}

.detail-lang-section, .detail-stats {
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  font-size: var(--base-font-size);
  overflow: hidden;
  column-gap: 15px;
}

.detail-stats {
  height: 20px;
  margin-top: 16px;

  .detail-type {
    height: 20px;
    font-size: 1em;

    span {
      margin-left: 4px;
      margin-top: 2px;
      font-size: 1.6em;
    }
  }
}

.detail-type, .detail-lang {
  height: 32px;
  background-color: transparent;
  box-sizing: border-box;
  font-family: "Inter Light", "Inter", sans-serif;
  font-size: 1.4em;
  text-align: left;
  line-height: normal;
  padding: 0 4px;
  display: flex;
}

.detail-type-image, .detail-lang-image, .add-cart-image {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  margin: 5px;
}

.detail-type-text, .detail-lang-text {
  padding-left: 4px;
}

.detail-desc-section {
  min-width: var(--detail-desc-width, 789px);
  margin-top: 19px;
  font-size: var(--base-font-size);
}

.detail-image {
  width: 372px;
  height: 248px;
}

.detail-description, .detail-description2 {
  width: 100%;
  overflow: hidden;
  text-align: left;
  font-size: 1.6em;
}

.detail-description2 {
  margin-top: 8px;
}

.detail-add-cart {
  width: 100%;
  text-align: right;
}

.detail-add-cart-left {
  margin-top: 28px;
  width: 100%;
}

.add-to-cart-button,
.add-to-cart-button-disabled {
  width: 254px;
  height: 48px;
  padding: 2px 2px 2px 2px;
  border-radius: 8px;
  border: 1px solid var(--borders-color);
  background-color: var(--button-color);
  box-sizing: border-box;
  color: var(--button-text-color);
  text-align: center;
  line-height: normal;
  font-size: 1.6em;
  margin-right: 20px;

  span {
    margin-left: 12px;
    position: relative;
    top: -5px;
  }
}

.add-to-cart-button-disabled {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}

.detail-children-box {
  width: 100%;
  text-align: left;
  margin-top: 16px;
  overflow: hidden;
  padding-left: 32px;
  padding-right: 40px;
}

.detail-child-item {
  width: 100%;
  text-align: left;
  margin-top: 16px;
  padding-bottom: 16px;
  display: inline flex;
  border-bottom: 1px solid var(--navigation-border-color);
}

.detail-child-content {
  width: 100%;
}

.detail-child-title {
  font-weight: bold;
  font-size: 1.4em;
}

.detail-child-descr {
  font-size: 1.2em;
}
