.cart {
  margin: 0px auto;
  margin-top: 136px;
  margin-bottom: 23px;
  width: var(--cart-component-width);
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
}

.cart-path {
  height: 19px;
  box-sizing: border-box;
  text-align: left;
  line-height: normal;
  font-size: 1.4em;
}

.cart-title {
  margin-top: 30px;
  height: 43px;
  box-sizing: border-box;
  font-weight: 300;
  text-align: left;
  line-height: normal;
  font-size: 3.2em;
}

.cart-box {
  margin-top: 24px;
  width: calc(var(--cart-component-width) - 32px);
  padding: 2px 2px 2px 2px;
  border-radius: 8px;
  border: 1px solid var(--borders-color);
  box-sizing: border-box;
}

.cart-items {
  display: flex;
  flex-direction: column;
  margin: 24px 20px;
  flex-wrap: 24px;
  border-bottom: 1px solid var(--borders-color);
}

.line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid var(--borders-color);
}

.cart-totals {
  height: 32px;
  width: 100%;
}

.cart-total {
  height: 32px;
  box-sizing: border-box;
  font-weight: 500;
  color: var(--price-text-color);
  text-align: right;
  line-height: normal;
  font-size: 2.4em;
  margin-right: 20px;
}

.button-area {
  width: 100%;
  margin-top: 25px;
  height: 48px;
  margin-bottom: 23px;
  text-align: right;
}

.checkout-button {
  width: 130px;
  height: 48px;
  padding: 2px 2px 2px 2px;
  border-radius: 8px;
  border: 2px solid var(--borders-color);
  background-color: var(--button-color);
  box-sizing: border-box;
  color: var(--button-text-color);
  text-align: center;
  line-height: normal;
  font-size: 1.6em;
  margin-right: 20px;
}

.empty-cart {
  font-size: 1.8em;
}

