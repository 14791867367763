.App {
  margin: 0px auto;
  width: var(--components-width);
  text-align: center;
  font-family: "Inter Light", Inter, sans-serif;
  font-size: 10px;
}

#main {
  margin-top: 61px;
  width: var(--components-width);
}