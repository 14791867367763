.banner {
  width: var(--components-width);
  max-height: 226px;
  padding: 2px 2px 2px 2px;
  background-color: var(--header-color);
  color: var(--header-text-color);
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  font-family: "Inter Light", Inter, sans-serif;
  font-size: 10px;
  overflow: hidden;

  div {
    margin: 0;
  }
}
